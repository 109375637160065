import { Brand } from '../models/brand.model';
import { DandomainToneOfVoices } from './tone-of-voice/dandomain.tone-of-voice';
import { DandomainTypography } from './typography/dandomain.typography';
import { DandomainColors } from './colors/dandomain.colors';
import { DandomainLogos } from './logos/dandomain.logos';
import { DandomainSocialMedia } from './social-media/dandomain.social-media';
import { DandomainTemplates } from './templates/dandomain.templates';

export const DandomainBrand: Brand = {
  name: 'dandomain.dk',
  alias: 'dandomain',
  displayName: 'DanDomain',
  colors: DandomainColors,
  logos: DandomainLogos,
  typography: DandomainTypography,
  toneOfVoice: DandomainToneOfVoices,
  socialMedia: DandomainSocialMedia,
  templates: DandomainTemplates
};
