import { ToneOfVoice } from '../../models/tone-of-voice.model';

export const DandomainToneOfVoices: ToneOfVoice = {
 updated: new Date(2023, 2, 7),
 keys: [
   { icon: `lokale.svg`, header: 'Lokale', description: 'Vi er lokale med dansk support. Vi taler et sprog som alle kan forstå. Vores datacentre er alle placeret i Danmark.' },
   { icon: `nemt.svg`, header: 'Nemt', description: 'Vi vil gøre det nemt og forståeligt for kunden. Vi guider kunden godt igennem for at gøre oplevelsen så nem og problemfri som mulig. Kunden skal aldrig føle sig til besvær.' },
   { icon: `troværdige.svg`, header: 'Troværdige', description: 'Vi følger med nyeste udvikling og arbejder hele tiden på at udvikle vores systemer, så de lever op til højeste standarder, både i forhold til sikkerhed og certificeringer. Vi holder, hvad vi lover. Vi har fokus på relationer og et godt samarbejde. ' },
   { icon: `tilgængelige.svg`, header: 'Tilgængelige', description: 'Vi er tilgængelige 365 dage om året på telefon og mail. Hos os er ingen spørgsmål for små, og vi er med dig hele vejen.' },
   { icon: `passionerede.svg`, header: 'Passionerede', description: 'Vi er passionerede. Vi hjælper og uddanner vores kunder til succes online. ' },
   { icon: `onestopshop.svg`, header: 'One stop shop', description: 'Vi tilbyder en bred palette af letforståelige produkter, som gør drømme mulige online.' }
 ],
 whatDoWeDo: [
  { header: 'Stemme', description: `
      <p>Vores stemme i Dandomain skal overordnet og konsistent udtrykke vores værdier lokale, nemt, troværdige, tilgængelige, passionerede og one stop shop.  </p>
      <p>Vi vægter samarbejde og tilgængelighed højt og er med kunden hele vejen. Vi skaber en stemme, der er uformel og jordnær, fremfor formel og distanceret. Vi er passionerede og bidrager med ekspertviden, der sikrer, at vores stemme forbliver pålidelig og seriøs.  </p>
      <p>Vi taler i øjenhøjde, uden et højtragende teknisk sprogniveau. Vi er familiære, og bruger personlige pronominer, såsom vi, du, os, vores, jeres osv.  </p>
      <p>Det er vigtigt, at vi er konsistente i vores stemme. Det skaber genkendelse hos vores kunder og styrker vores troværdighed som samarbejdspartner og brand.  </p>
  `},
  { header: 'Tone', description: `
      <p>Vores tone er frisk, uformel, personlig og inviterende. Vi retter altid vores tone mod den laveste tekniske fællesnævner. Vi kan efterfølgende justere tonen ud fra teknisk niveau. Vi sørger for, at budskaberne bliver forklaret, men vi taler aldrig ned til modtageren.  </p>
      <p>Tonen varieres ud fra målgruppe, kontekst og platform. Hvis budskabet er informativt, vil tonen være mere direkte. Hvis budskabet har til hensigt at skabe engagement, er tonen mere legende og inkluderende. </p>
  `}, ],
  doAndDonts: [
    {
      title: 'Website',
      header: 'Eksempler på vores website',
      doDonts: [
        {
          title: 'Blogindlæg',
          do: {
            description: `
              <p>Nogen gange sker det, at du mister alt det arbejde, du har brugt timer, uger - ja måske måneder eller år på. Vi har alle prøvet det i et omfang, der har givet tårer i øjnene eller et pludselig udbrud af eder og forbandelser.</p>
              <p>Datatab kan have alvorlige konsekvenser for din virksomhed, så hvis du har ét eller flere Office 365 produkter, så bør du få Office 365 Backup. Hvorfor? Det og meget andet kan du læse om i dette blogindlæg.</p>
            `,
            reason: `Husk altid at overveje, hvordan du får målgruppen på ”krogen”, så de får lyst til at læse med videre i blogindlægget. Skriv personligt og inviter målgruppen ind i butikken. Brug ”du” i stedet for ”man”. Skriv mere som du taler, så det bliver letlæseligt og mere fængende.`
          },
          dont: {
            description: `Man skal sikre sig mod datatab med Office 365 Backup, fordi man kan risikere at komme ud for datatab, der kan have konsekvenser for ens virksomhed. Office 365 Backup giver automatisk backup hver fjerde time og mulighed for gendannelse af data, hvis man kommer ud for datatab. Derudover får man adgang til dansk support hos DanDomain. Læs med i blogindlægget her.  `,
            reason: `Undgå at skrive distanceret og upersonligt. Undgå at pådutte målgruppen et produkt. Tal i stedet ind i målgruppens behov og udfordringer, der er relateret til emnet.`
          }
        },
        {
          title: 'Om os',
          do: {
            description: `I DanDomain arbejder vi hver dag for at gøre din online oplevelse så nem og problemfri som mulig, så du i stedet kan bruge din energi på det, som du brænder for. Vi tilbyder domæner, webshopløsninger, e-mail, office, hjemmesider og webhoteller. Fælles for vores produkter er, at de skal bidrage til din succes online.`,
            reason: `Vigtigt at brandværdierne skinner igennem. “Show it don’t tell it”. F.eks. udfold, hvordan det helt konkret kommer til udtryk, at vi tilbyder ”god support”, som i dette eksempel.`
          },
          dont: {
            description: `DanDomain har eksisteret siden 1999. I dag har vi en halv million registrerede domæner. Vi er eksperter i IT og hjemmesider. Vi tilbyder gode hjemmesideløsninger og god support.`,
            reason: `-	Undgå at fremstå selvcentreret ved at fremhæve alle kvaliteter ved DanDomain selv. Fremhæv i stedet hvilken forskel vi ønsker at skabe for kunderne.`
          }
        }
      ]
    }, {
      title: 'Sociale Medier',
      header: 'Eksempler på Tone of Voice på sociale medier.',
      description: 'Vi skal gøre de sociale medier sociale. Det gør vi bl.a. med billeder og personlighed. Sæt gerne ansigt på og invitér dem ”indenfor i butikken”. Vores værdier som lokale, nemt, troværdige, tilgængelige, passionerede og one stop shop skal skinne igennem på tværs af platforme. Det skaber en genkendelighed hos vores kunder og styrker vores troværdighed. Derimod, når det gælder vores tone, og det content vi deler, er det vigtigt, at vi formår at tilpasse kommunikationen til de forskellige sociale medier.   ',
      doDonts: [
        {
          title: 'LinkedIn',
          do: {
            image: `linkedin-1.png`,
            reason: `Dette opslag er mere indbydende for målgruppen, og start gerne ud med hvilket behov der løses. Skriv professionelt og personligt. Brug emojies, da de skaber likfang. Skab luft mellem afsnittene.`
          },
          dont: {
            image: 'linkedin-2.png',
            reason: `Undgå af skrive salgsorienteret ligesom dette eksempel. Undgå for lange sammensatte sætninger og skriv ikke distanceret.`
          }
        },
        {
          title: 'Facebook',
          do: {
            image: `facebook-1.png`,
            reason: `Inddrag målgruppen og inviter dem ind i butikken. Skriv personligt og tal ind i målgruppens behov og udfordringer. Brug emojies og mellemrumstasten og skab luft mellem afsnittene. Sørg for at vores brandværdier skinner igennem opslaget.`
          },
          dont: {
            image: 'facebook-2.png',
            reason: `Undgå at skrive for salgsorienteret og pådutte dem noget. Hav i stedet fokus på, hvilken værdi du helt konkret kan skabe for kunderne. Undgå at skrive distanceret og upersonligt. `
          }
        }
      ]
    }, {
      title: 'E-mails',
      header: 'Eksempler på Tone of Voice i nyhedsmails',
      description: 'Anvend en personlig og imødekommende tone. Tag udgangspunkt i, hvad formålet med nyhedsmailen er og hvilken relevans det har for kunden. Hvilke behov kan kunden få dækket? Hvilken betydning har det for kunden? Gør det klart, at kunden altid gerne må tage kontakt, og at vi er med kunden hele vejen.',
      doDonts: [
        {
          do: {
            image: 'mail-1.png',
            reason: `Denne mail har mere fokus på, hvilken værdi emnet skaber for målgruppen. Hvilke udfordringer og behov kan målgruppen få dækket? Den er mere luftigt og inddrager målgruppen og inviter dem ind i butikken. Skær teksten ind til benet. Gør det kort og klart, da vores målgruppe bombaderes med mails hver dag.`
          },
          dont: {
            image: 'mail-2.png',
            reason: `Undgå at bruge lange og kringlede sætninger. Undgå at skrive upersonligt. Det gør du f.eks. ved at bruge ”du” i stedet for ”man”. Undgå at skrive for salgsorienteret og pådutte målgruppen noget.`
          }
        }
      ]
    }
  ],
};
